import React, { useState, useEffect } from 'react';
import { Grid, Typography, TextField } from '@mui/material';
import { updateCurrentNumber } from '../services/mongo';
import InvoiceTable from '../components/InvoiceTable';
import DashboardLayout from '../layouts/DashboardLayout';

const API_KEY = "u7sMitcdeLHYg5mtyskpKfMSQxTrssZQmKPJT4MzEINLUt21bWZJ45r9XBaHqqGD";
const API_URL = "https://trs-proxy.adaptable.app/https://eu-central-1.aws.data.mongodb-api.com/app/data-vbtqvkq/endpoint/data/v1"

const Dashboard = ({onLogout}) => {
  const [currentNumber, setCurrentNumber] = useState("");
  const [disableInput, setDisableInput] = useState(false);

  useEffect(() => {
    fetch(`${API_URL}/action/findOne`, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        'apiKey': API_KEY,
      },
      method: 'POST',
      body: JSON.stringify({
        dataSource: "Agrocosm",
        database: "agrocosm",
        collection: "data"
      })
    })
    .then(res => res.json())
    .then(data => {
      setCurrentNumber(data.document.currentNumber);
    })
    .catch(err => console.error(err));
  }, [])

  const tryUpdateCurrentNumber = (e) => {
    const newNumber = e.target.value;
    setDisableInput(true);
    updateCurrentNumber(currentNumber, newNumber)
      .then(() => {
        setCurrentNumber(newNumber);
        setDisableInput(false);
      })
      .catch(err => {
        console.error(err);
        setDisableInput(false);
      });
  }

  return (
    <DashboardLayout onLogout={onLogout}>
      <Grid container alignItems="center" sx={{mb: 3}}>
        <Grid item>
          <Typography variant="h6" sx={{mr: 1}}>
            Număr curent: 
          </Typography>
        </Grid>
        <Grid item>
          <TextField
            disabled={disableInput}
            type="number"
            margin="none"
            label=""
            value={currentNumber}
            onChange={tryUpdateCurrentNumber}
            InputProps={{
              startAdornment: <>B</>,
            }}
            sx={{
              '& .MuiInputBase-input': {
                padding: 1,
              }
            }}
          />
        </Grid>
        <Grid item>
          <Typography variant="p" sx={{ml: 1, fontSize: 12 }}>
            (acesta este numărul cu care se va genera următoarea declarație)
          </Typography>
        </Grid>
      </Grid>
      <InvoiceTable 
        currentNumber={currentNumber}
        tryUpdateCurrentNumber={tryUpdateCurrentNumber}
      />
    </DashboardLayout>
  );
};

export default Dashboard;
