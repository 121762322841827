import axios from 'axios';
import pdfToText from 'react-pdftotext'

const API_URL = 'https://ws.smartbill.ro/SBORO/api';
const email = 'trascaandrei17@gmail.com';
const token = '001|a0455a533e02450d8801d122f1c4b1ed';
const cif = 'RO16914446';

const authHeaders = {
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json'
  },
  auth: {
    username: email,
    password: token
  }
};

// Get all series
export const getAllSeries = async () => {
  try {
    const response = await axios.get(`${API_URL}/series?cif=${cif}&type=f`, {
      ...authHeaders
    });
    return response.data.list;
  } catch (error) {
    console.error('Error fetching series:', error);
    return [];
  }
};

// Get invoice details by number
export const getInvoiceDetails = async (seriesName, invoiceNumber) => {
  try {
    const response = await axios.get(`${API_URL}/documents/invoice/${seriesName}/${invoiceNumber}`, {
      headers: {
        ...authHeaders,
      }
    });
    return response.data;
  } catch (error) {
    console.error(`Error fetching invoice details for ${seriesName}-${invoiceNumber}:`, error);
    return null;
  }
};

// Get invoices by counting backwards from the last invoice number
export const getInvoices = async (seriesName, lastInvoiceNumber, count = 10) => {
  const invoices = [];

  for (let i = 0; i < count; i++) {
    invoices.push({
      id: lastInvoiceNumber - i,
      series: seriesName,
      number: lastInvoiceNumber - i,
      name: `${seriesName}${lastInvoiceNumber - i}`,
      downloadLink: `${API_URL}/invoice/pdf?cif=${cif}&seriesname=${seriesName}&number=${lastInvoiceNumber - i}`
    })
  }

  return invoices;
};

export const downloadInvoice = async (name, downloadLink) => {
  try {
    const response = await axios.get(downloadLink, {
      ...authHeaders,
      responseType: 'blob'
    });

    // Create a new Blob object using the response data
    const blob = new Blob([response.data], { type: 'application/pdf' });
    const url = window.URL.createObjectURL(blob);

    // Create a link element
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', name); 

    // Append the link to the document body and click it to start download
    document.body.appendChild(link);
    link.click();

    // Cleanup: remove the link after download
    link.parentNode.removeChild(link);
  } catch (error) {
    console.error('Error downloading invoice:', error);
  }
};

function extractDataFromText(text) {
  const products = [];
  const regexPatterns = [
    /(\d+)\s+\(COD:\s*([\d-]+)\)\s+(.+?)\s+(\w+)\s+(-?\d+)\s+([\d.]+)\s+(-?[\d.]+)\s+(-?[\d.]+)/g,
    /(\d+)\s+\((\d+)\)\s+(.+?)\s+(\w+)\s+(-?\d+)\s+([\d.]+)\s+(-?[\d.]+)\s+(-?[\d.]+)/g,
    /(\d+)\s+(\w+)\s+\(([\d-]+)\)\s+(.+?)\s+(-?\d+)\s+([\d.]+)\s+(-?[\d.]+)/g,
    /(\d+)\s+(\w+)\s+\(COD:\s*([\d-]+)\)\s+(.+?)\s+(-?\d+)\s+([\d.]+)\s+(-?[\d.]+)/g,
  ];

  regexPatterns.some(pattern => {
    let match;
    while ((match = pattern.exec(text)) !== null) {
      const id = parseInt(match[1], 10);
      const cod = match[3].trim();
      const denumire = match[4].trim();
      const cant = parseInt(match[5], 10);

      products.push({
        id,
        cod,
        denumire,
        cant
      });
    }
    return products.length > 0;
  });

  return products;
}


export const getInvoiceBlob = async (downloadLink) => {
  const file = await fetch(downloadLink, {
    headers: {
      ...authHeaders.headers,
      'Authorization': `Basic ${btoa(`${email}:${token}`)}`
    }
  })
    .then(res => res.blob())
    .catch(error => console.error(error))

  return file;
}

export const getProductsFromInvoice = async (file) => {
  const text = await pdfToText(file)
    .catch(error => console.error(error));

  const products = extractDataFromText(text);
  return products;
}