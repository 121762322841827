import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { FONT_BASE64, HEADER_IMAGE_BASE64, SIGNATURE_IMAGE_BASE64 } from './assets';

function formatPercentage(value) {
  if (!value) {
    return {
      germinatie: "",
      puritate: "",
    };
  }

  // Convert to string if it's not already
  let strValue = value.toString();

  // Check if the value already ends with '%'
  if (strValue.endsWith('%')) {
    return {
      germinatie: strValue,
      puritate: "99%"
    }
  }

  // Convert decimal format (e.g., 0.75 or 0.7) to percentage format
  if (strValue.includes('.')) {
    let decimalValue = parseFloat(strValue);
    return {
      germinatie: Math.round(decimalValue * 100) + '%',
      puritate: "99%"
    }
  }

  // Handle the case where value is an integer (e.g., 75)
  return {
    germinatie: strValue + '%',
    puritate: "99%"
  }
}

const generatePDF = async (productDetails) => {
  const doc = new jsPDF();

  // Add custom font to jsPDF
  doc.addFileToVFS("custom.ttf", FONT_BASE64);
  doc.addFont("custom.ttf", "custom", "normal");
  doc.setFont("custom");

  // Adaugă imaginea de header
  doc.addImage(HEADER_IMAGE_BASE64, 'JPG', 10, 10, 190, 30);

  doc.setFontSize(14);
  doc.text('DECLARATIE DE CONFORMITATE', doc.internal.pageSize.getWidth() / 2, 50, { align: 'center' });

  doc.setFontSize(10);
  doc.text(`Aviz: ${productDetails.aviz}`, doc.internal.pageSize.getWidth() / 2, 60, { align: 'center' });
  doc.text(`Data: ${productDetails.data}`, doc.internal.pageSize.getWidth() / 2, 65, { align: 'center' });
  doc.text(`${productDetails.currentNumber}`, doc.internal.pageSize.getWidth() / 2, 70, { align: 'center' });

  const declaratieText = 'Noi SC AGROCOSM CONSULT SRL, cu sediul în Str. Ana Ipatescu, nr 1A, având Certificat de Înregistrare RO16914446 asigurăm, garantăm și declarăm pe propria răspundere, conform prevederilor art. 5 din Hotărârea de Guvern nr. 10022/2002 privind regimul produselor și serviciilor ce pot pune în pericol viața, sănătatea, securitatea muncii și protecția mediului, că produsele comercializate de noi, în baza facturilor emise de către SC AGROCOSM CONSULT SRL cu codurile de produse următoare:';

  doc.text(declaratieText, 20, 80, { maxWidth: 170 });
  
  let hasGermination = false;
  productDetails.products
    .find(product => product.germinatie !== undefined) && (hasGermination = true);
  console.log(hasGermination);

  let tableHead = [];
  let tableBody = {};

  if (hasGermination) {
    tableHead = ['COD ART', 'DENUMIRE', 'LOT', 'GERMINAȚIE', 'PURITATE', 'NR BUC'];
    tableBody = productDetails.products.map(product => {
      const { germinatie, puritate } = formatPercentage(product.germinatie);

      return [
        product.cod, product.denumire, product.lot,
        germinatie, puritate, product.cant
      ]
  });
  } else {
    tableHead = ['COD ART', 'DENUMIRE', 'LOT', 'NR BUC'];
    tableBody = productDetails.products.map(product => [product.cod, product.denumire, product.lot, product.cant]);
  }

  // Adaugă tabelul cu detalii despre produse
  doc.autoTable({
    startY: 105,
    head: [tableHead],
    body: tableBody,
    headStyles: { fillColor: [26, 61, 37] },
    styles: { font: 'custom' },
    pageBreak: 'auto'
  });

  const conformitateText1 = `Toate produsele sunt fabricate in Conformitate cu Regulametul European Ecologic 848/2018 Fertilizant EU, CF.REG.CE 1009/2019, CMC.1, PFC.1`;
  const conformitateLines1 = doc.splitTextToSize(conformitateText1, 170);

  const conformitateText2 = `Toate produsele la care se refera aceasta declatie de conformitate nu pun in pericol viata, sănătatea, securitatea muncii si nu produc un impact negativ asupra mediului daca sunt utilizate conform instructiunilor si sunt in conformitate cu normele in viguare ale Uniunii Europene.`;
  const conformitateLines2 = doc.splitTextToSize(conformitateText2, 170);

  const completataText = `Aceasta Declaratie a fost completată de cătr Ureche Constantina Cosmina, Administrator al AGROCOSM CONSULT SRL`;
  const completataLines = doc.splitTextToSize(completataText, 170);

  let currentY = doc.autoTable.previous.finalY + 10;

  // Verifică dacă mai este suficient spațiu pe pagină, altfel adaugă o nouă pagină
  if (currentY + doc.getTextDimensions(conformitateLines1).h + 10 > doc.internal.pageSize.getHeight()) {
    doc.addPage();
    currentY = 20;
  }

  doc.text(conformitateLines1, 20, currentY);
  currentY += doc.getTextDimensions(conformitateLines1).h + 5;

  if (currentY + doc.getTextDimensions(conformitateLines2).h + 10 > doc.internal.pageSize.getHeight()) {
    doc.addPage();
    currentY = 20;
  }

  doc.text(conformitateLines2, 20, currentY);
  currentY += doc.getTextDimensions(conformitateLines2).h + 10;

  if (currentY + doc.getTextDimensions(completataLines).h + 20 > doc.internal.pageSize.getHeight()) {
    doc.addPage();
    currentY = 20;
  }

  doc.text(completataLines, 20, currentY);
  currentY += doc.getTextDimensions(completataLines).h + 10;

  if (currentY + doc.getTextDimensions("a").h * 4 + 40 > doc.internal.pageSize.getHeight()) {
    doc.addPage();
    currentY = 20;
  }

  doc.text('Ureche Constantina Cosmina', 20, currentY);
  currentY += 5;
  doc.text('Administrator', 20, currentY);
  currentY += 5;
  doc.text('Bucuresti', 20, currentY);
  currentY += 5;
  doc.text(productDetails.data, 20, currentY);

  // Adaugă imaginea de semnătură
  doc.addImage(SIGNATURE_IMAGE_BASE64, 'PNG', 150, currentY, 48, 48);

  // Salvează documentul
  doc.save(`Declaratie_${productDetails.aviz}.pdf`);
};

export default generatePDF;
