import React from "react";
import { Dialog, DialogContent, DialogTitle, Typography, CircularProgress, Box } from "@mui/material";

export function ActionLoader({ isEditDialogOpen, loadingText }) {
	return (
		<Dialog
			open={isEditDialogOpen}
			fullWidth
		>
			<DialogTitle>
				Așteptați...
			</DialogTitle>
			<DialogContent dividers>
                <Box 
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    marginTop="50px"
                >
                    <CircularProgress size={60}/>
                    <Typography variant="subtitle1" gutterBottom mt="10px">
                        {loadingText}
                    </Typography>
                </Box>
			</DialogContent>
		</Dialog>
	)
}