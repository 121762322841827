import React from "react";

import DashboardBar from "../components/DashboardBar";
import AuthContent from "../components/AuthContent";
import { Container } from "@mui/material";


export default function DashboardLayout({ onLogout, children }) {
  return (
    <AuthContent>
        <DashboardBar onLogout={onLogout} />
        <Container maxWidth="xl" style={{marginTop: '50px'}}>
            {children}
        </Container>
    </AuthContent>
  );
}