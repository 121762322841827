import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import { Menu, MenuItem } from '@mui/material';

function DashboardBar({onLogout}) {
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const settings = [
    {
      name: 'Logout',
      action: () => {
        onLogout();
      }
    }
  ];

  return (
    <AppBar position="static" style={{background: '#efefef'}}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <a href="/dashboard">
            <Box 
              component="img"
              src="/logo.png"
              alt="logo"
              sx={{
                width: {
                  xs: '100px',
                  md: '150px'
                }
              }}
            />
          </a>
          

          <Box
            sx={{
              flexGrow: 1,
              marginLeft: {
                sx: '15px',
                md: '30px'
              }
            }}
          >
            <Typography 
              component="a" 
              variant="h3" 
              sx={{
                color: '#1a3d25',
                textDecoration: 'none' ,
                fontSize: {
                  xs: '2rem',
                  md: '3rem'
                }
              }} 
              href="/dashboard">
              Generare declarație
            </Typography>
          </Box>

          <Box sx={{ flexGrow: 0 }}>
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar />
              </IconButton>
              <Menu
                sx={{ mt: '45px' }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {settings.map((setting) => (
                  <MenuItem key={setting.name} onClick={setting.action}>
                    <Typography textAlign="center">{setting.name}</Typography>
                  </MenuItem>
                ))}
              </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default DashboardBar;