import * as XLSX from 'xlsx';

class ExcelProcessor {
  constructor(fileBuffer) {
    if (ExcelProcessor.instance) {
      return ExcelProcessor.instance;
    }

    this.fileBuffer = fileBuffer;
    this.data = [];
    this.parseFile();
    ExcelProcessor.instance = this;
  }

  parseFile() {
    const workbook = XLSX.read(this.fileBuffer, { type: 'array' });
    const sheets = workbook.SheetNames;

    sheets.forEach((sheetName) => {
      const worksheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(worksheet);
      this.data = this.data.concat(jsonData);
    });

  }

  searchProductByCode(code) {
    return this.data.find(row => String(row['cod produs']) === String(code)) || null;
  }
}

let instance;

async function createInstance(filePath) {
  if (instance) {
    return instance;
  }

  try {
    const response = await fetch(filePath);
    const buffer = await response.arrayBuffer();
    instance = new ExcelProcessor(buffer);
    return instance;
  } catch (error) {
    console.error('Error fetching Excel file:', error);
    throw error;
  }
}

const excelProcessorPromise = createInstance('/DECLARATIE - MACHETA.xlsx');

export default excelProcessorPromise;
