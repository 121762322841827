import { Skeleton } from "@mui/material";

export function Loader(props) {
    if (!props.isLoading) {
        return props.children;
    }
    
    return (
        <Skeleton
            variant="rounded"
            width={'100%'}
            height={props.height} 
        />
    )
};
