import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { CircularProgress, Box } from "@mui/material";

export default function AuthContent({ children }) {
  const loggedIn = localStorage.getItem('isAuthenticated') === 'true';
  const navigate = useNavigate();

  // Navigate unauthenticated users to Log In page.
  useEffect(() => {
    if (!loggedIn) {
        navigate('/');
    }
  }, [loggedIn, navigate]);

  if (loggedIn) {
    return <>{children}</>;
  }

  return (
    <Box 
      height="100vh"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <CircularProgress size={60}/>
    </Box>
  );
}