const API_KEY = "u7sMitcdeLHYg5mtyskpKfMSQxTrssZQmKPJT4MzEINLUt21bWZJ45r9XBaHqqGD";
const API_URL = "https://trs-proxy.adaptable.app/https://eu-central-1.aws.data.mongodb-api.com/app/data-vbtqvkq/endpoint/data/v1"

export const updateCurrentNumber = async (currentNumber, newNumber) => {
    const res = await fetch(`${API_URL}/action/updateOne`, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        'apiKey': API_KEY,
      },
      method: 'POST',
      body: JSON.stringify({
        dataSource: "Agrocosm",
        database: "agrocosm",
        collection: "data",
        filter: { currentNumber: currentNumber },
        update: { 
          "$set": { currentNumber: newNumber }
        }
      })
    })

    const data = await res.json();
    
    if (data.modifiedCount === 1) {
      return newNumber;
    } else {
      throw new Error("Failed to update current number");
    }
}