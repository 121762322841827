import React, { useEffect, useState } from "react";
import {
  Button,
  Grid,
  IconButton,
  TextField,
} from "@mui/material";
import { Search, Clear } from "@mui/icons-material";
import { DataGrid } from '@mui/x-data-grid';
import { Loader } from "./Loader";
import { escapeRegExp } from "@mui/x-data-grid/utils/utils";
import { getAllSeries, getInvoices, getInvoiceBlob, getProductsFromInvoice, downloadInvoice } from "../services/smartbill.js"
import excelProcessorPromise from '../services/excel';
import generatePDF from '../services/pdf';
import { ActionLoader } from "./ActionLoader.jsx";

export default function InvoiceTable({ currentNumber, tryUpdateCurrentNumber }) {
  const [invoices, setInvoices] = useState([]);
  const [tableRows, setTableRows] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [initialRows, setInitialRows] = useState([]);
  const [excelProcessor, setExcelProcessor] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [loadingText, setLoadingText] = useState("");

  const fileInput = React.useRef();

  useEffect(() => {
    setIsLoading(true);

    const loadExcelProcessor = async () => {
      try {
        const instance = await excelProcessorPromise;
        setExcelProcessor(instance);
      } catch (error) {
        console.error('Error loading Excel Processor:', error);
      }

      const fetchInvoices = async () => {
        const series = await getAllSeries();
        if (series.length > 0) {
          const seriesName = series[0].name; // You may choose a different series if needed
          const lastInvoice = series[0].nextNumber - 1;
          const data = await getInvoices(seriesName, lastInvoice, 1000);
          setInvoices(data);
        }
        setIsLoading(false);
      };
  
      fetchInvoices();
    };

    loadExcelProcessor();
  }, []);

  useEffect(() => {
    setTableRows(invoices);
    setInitialRows(invoices);
  }, [invoices]);

  const generateDeclaration = async (invoice) => {
    // step 1: get invoice
    setLoadingText("1/4 Se descarcă factura...");
    setIsEditDialogOpen(true);
  
    const invoiceFile = await getInvoiceBlob(invoice.downloadLink);

    // step 2: extract products from invoice
    setLoadingText("2/4 Se extrag datele din factură...");
    let products = await getProductsFromInvoice(invoiceFile);

    // step 3: get data from Excel
    setLoadingText("3/4 Se caută datele în Excel...");
    products = products.map((product) => {
      const data = excelProcessor.searchProductByCode(product.cod);

      return {
        ...product,
        ...data
      };
    });

    // step 4: create declaration PDF
    setLoadingText("4/4 Se crează declarația...");

    await generatePDF({
      products: products,
      aviz: invoice.number,
      data: new Date().toLocaleDateString(),
      currentNumber,
    });

    setIsEditDialogOpen(false);

    const newNumber = Number(currentNumber) + 1;
    tryUpdateCurrentNumber({ target: { value: newNumber } });
  }

  const generateDeclarationFromAviz = async (aviz) => {
    setIsEditDialogOpen(true);

    // step 1: extract products from invoice
    setLoadingText("1/3 Se extrag datele din aviz...");
    let products = await getProductsFromInvoice(aviz);

    // step 3: get data from Excel
    setLoadingText("2/3 Se caută datele în Excel...");
    products = products.map((product) => {
      const data = excelProcessor.searchProductByCode(product.cod);

      return {
        ...product,
        ...data
      };
    });

    // step 4: create declaration PDF
    setLoadingText("3/3 Se crează declarația...");

    function extractNumberFromFilename(filename) {
        const numberRegex = /\d+/;
        const match = filename.match(numberRegex);
        return match ? match[0] : null;
    }

    await generatePDF({
      products: products,
      aviz: extractNumberFromFilename(aviz.name),
      data: new Date().toLocaleDateString(),
      currentNumber,
    });

    setIsEditDialogOpen(false);

    const newNumber = Number(currentNumber) + 1;
    tryUpdateCurrentNumber({ target: { value: newNumber } });
  }

  const requestSearch = (searchValue) => {
      const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
      const filteredRows = initialRows.filter((row) => {
          return Object.keys(row).some((field) => {
              return searchRegex.test(row[field]?.toString());
          });
      });
      setTableRows(filteredRows);
  };

  const tableColumns = [
      { 
          field: 'id', 
          headerName: "Factura", 
          flex: 1, 
          headerClassName: 'idd-table-header',
          headerAlign: 'center',
          align: 'center',
          filterable: false,
          disableColumnMenu: true,
          renderCell: (params) => (
              <b>
                {params.row.name}
              </b>
          )
      },
      {
          field: 'actions',
          headerName: "Acțiuni",
          sortable: false,
          filterable: false,
          flex: 2,
          headerClassName: 'idd-table-header',
          headerAlign: 'center',
          align: 'center',
          disableColumnMenu: true,
          renderCell: (params) => (
              <>
                  <Button
                      variant="contained"
                      onClick={async () => {
                        setLoadingText("Se descarcă factura...");
                        setIsEditDialogOpen(true);
                        await downloadInvoice(params.row.name, params.row.downloadLink);
                        setIsEditDialogOpen(false);
                      }}
                      sx={{mr: '10px'}}
                  >
                      Descarcă factura
                  </Button>
                  <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => generateDeclaration(params.row)}
                  >
                      Generează declarație
                  </Button>
              </>
          )
      }
  ];

  return (
      <Loader
          isLoading={isLoading}
          height={600}
      >
          <Grid container alignItems="center" mb={3}>
              <Grid 
                  item 
                  xs={12} 
                  md={8}
                  display="flex"
                  alignItems="center"
              >
                <Button 
                    variant="contained" 
                    color="primary" 
                    onClick={()=>fileInput.current.click()}
                >
                    Încarcă Aviz
                </Button>

                <input 
                    ref={fileInput} 
                    type="file" 
                    style={{ display: 'none' }}
                    onChange={(e) => generateDeclarationFromAviz(e.target.files[0])}
                />
              </Grid>
              <Grid 
                  item 
                  xs={12} 
                  md={4}
                  sx={{
                      mt: { xs: 2, md: 0 },
                  }}
              >
                  <TextField
                      variant="outlined"
                      value={searchText}
                      onChange={(e) => { setSearchText(e.target.value); requestSearch(e.target.value) }}
                      placeholder="Caută"
                      InputProps={{
                          startAdornment: <Search fontSize="small" color="action" />,
                          endAdornment: (
                              <IconButton
                                  title="Clear"
                                  aria-label="Clear"
                                  size="small"
                                  style={{ visibility: searchText ? 'visible' : 'hidden', borderRadius: "57%", paddingRight: "1px", margin: "0", fontSize: "1.25rem" }}
                                  onClick={(e) => {setSearchText(''); setTableRows(initialRows)} }
                              >
                                  <Clear fontSize="small" color="action" />
                              </IconButton>
                          ),
                      }}
                      sx={{
                          float: 'right',
                          width: { xs: 1, sm: 'auto' },
                          '& .MuiSvgIcon-root': {
                              mr: 0.5,
                          },
                          '& .MuiInput-underline:before': {
                              borderBottom: 1,
                              borderColor: 'divider',
                          },
                          '& .MuiInputBase-input': {
                              padding: 1
                          }
                      }}
                  />
              </Grid>
          </Grid>

          <DataGrid
              rows={tableRows}
              columns={tableColumns}
              disableRowSelectionOnClick
              disableColumnSelector
              initialState={{
                  pagination: {
                      paginationModel: { page: 0, pageSize: 10 },
                  },
              }}
              pageSizeOptions={[5, 10, 15]}
              sx={{
                  boxShadow: 2,
              }}
          />

          <ActionLoader
            isEditDialogOpen={isEditDialogOpen}
            loadingText={loadingText}
          />
      </Loader>
  )
}

